import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './footer.module.scss';
import Text from '../text';
import Link from '../link';
import { useDeviceDetect } from 'hooks';
import Image from '../image';

const defaultCompanyInfo = {
	companyName: 'InnovDr AB',
	orgNr: 'ORGNR: 559059-3256',
	connectedToSormland: true,
};

const Footer = ({ companyInfo, logo, links, tryggEHandelId, legitScriptId }) => {
	const { isSmallScreen } = useDeviceDetect();
	const orgNr = companyInfo?.orgNr || defaultCompanyInfo.orgNr;
	const companyName = companyInfo?.companyName || defaultCompanyInfo.companyName;

	let connectedToSormland;
	if (companyInfo?.connectedToSormland === undefined) {
		connectedToSormland = defaultCompanyInfo.connectedToSormland;
	} else {
		connectedToSormland = companyInfo?.connectedToSormland;
	}

	if (isSmallScreen) {
		return (
			<footer className={styles.footer}>
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col">
							{links.map((link, ix) => (
								<Fragment key={'footer-link-' + ix.toString()}>
									<Link href={link.url} text={link.text} />
									{ix < links.length - 1 && <br />}
								</Fragment>
							))}
						</div>
						<div className="col text-center">
							{tryggEHandelId && <div className="teh-certificate"></div>}
						</div>
						{connectedToSormland && (
							<div className="col text-center">
								<Image
									width={60}
									height={60}
									src="/images/logo_region_sormlands_lan.svg"
									alt="Kollarna.se erbjuder vård som ingår i vårdvalet i Region Sörmland."
								/>
							</div>
						)}
					</div>

					<Image src={logo} alt="logo" className={styles.logo} height={40} />
					<div>
						<Text tag="link">{companyName}</Text>
					</div>
					<div>
						<Text tag="link">{orgNr}</Text>
					</div>
					<div>
						<Text tag="link">Copyright @ 2016-{new Date().getFullYear()}</Text>
					</div>
				</div>
				<div className="row align-items-center">
					<div className="col p-4">
						Kollarna är en del av Doktor.se-koncernen och stödjer flera av
						vårdcentralerna i Doktor.se-gruppen inom ramen för respektive vårdcentrals
						regionsavtal. Samarbetet utökas successivt.
					</div>
				</div>
			</footer>
		);
	}

	return (
		<footer className={styles.footer}>
			<div className="container">
				<div className="row align-items-center">
					{legitScriptId && (
						<div className="col p-2">
							<a
								href="https://legitscript.com"
								target="_blank"
								title="Verify LegitScript Approval"
								rel="noreferrer"
							>
								<img
									loading="lazy"
									src={`https://static.legitscript.com/seals/${legitScriptId}.png`}
									alt="LegitScript approved"
									width="140"
									height="100"
								/>
							</a>
						</div>
					)}
					{tryggEHandelId && (
						<div className="col p-2">
							<div className="teh-certificate"></div>
						</div>
					)}
					{connectedToSormland && (
						<div className="col p-2">
							<div className="text-center">
								<Image
									width={60}
									height={60}
									src="/images/logo_region_sormlands_lan.svg"
									alt="Kollarna.se erbjuder vård som ingår i vårdvalet i Region Sörmland."
								/>
							</div>
							<div style={{ fontSize: '.7rem' }}>
								Kollarna.se erbjuder som ingår i vårdvalet i Region Sörmland.
							</div>
						</div>
					)}

					<div className="col p-2" style={{ marginRight: '10px' }}>
						{links.map((link, ix) => (
							<Fragment key={'footer-link-' + ix.toString()}>
								<Link href={link.url} text={link.text} />
								{ix < links.length - 1 && <br />}
							</Fragment>
						))}
					</div>

					<div className={styles.borderLeft + ' col p-2'}>
						<Image src={logo} alt="logo" className={styles.logo} height={40} />
						<div>
							<Text tag="link">{companyName}</Text>
						</div>
						<div>
							<Text tag="link">{orgNr}</Text>
						</div>
						<div>
							<Text tag="link">Copyright @ 2016-{new Date().getFullYear()}</Text>
						</div>
					</div>
				</div>
				<div className="row align-items-center">
					<div className="col p-2">
						<span style={{ fontSize: '.9rem' }}>
							Kollarna är en del av Doktor.se-koncernen och stödjer flera av
							vårdcentralerna i Doktor.se-gruppen inom ramen för respektive
							vårdcentrals regionsavtal. Samarbetet utökas successivt.
						</span>
					</div>
				</div>
			</div>
		</footer>
	);
};

Footer.propTypes = {
	logo: PropTypes.string.isRequired,
	links: PropTypes.array.isRequired,
};

Footer.defaultProps = {
	links: [],
};

export default Footer;
