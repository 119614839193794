import React from 'react';
import Head from 'next/head';
import styles from './layout.module.scss';
import CookieConsent from '../cookie-consent';
import Footer from '../footer';
import Navbar from '../navbar';
import PreviewBanner from '../preview-banner';
import { useGlobalMessageHandler } from 'hooks';
import { useUtmSessionStorage } from 'hooks';
import appConfig from '../../config.json';

const createStructuredData = (items) => {
	const structedDataSettings = items.find((element) => {
		if (element.__component === 'sections.accordion') {
			return element;
		}
	});
	if (!structedDataSettings?.items.length > 0) return;
	const mainEntities = structedDataSettings.items.map((item) => {
		return {
			'@type': 'Question',
			name: item.title,
			acceptedAnswer: {
				'@type': 'Answer',
				text: item.paragraph,
			},
		};
	});
	return {
		'@context': 'https://schema.org',
		'@type': 'FAQPage',
		mainEntity: mainEntities,
	};
};

const getCanonicalUrl = (slug) => {
	return appConfig.canonicalBaseURL + '/' + (slug ? slug : '');
};

const Layout = ({
	configuration,
	seo,
	preview,
	preload,
	children,
	useSelfReferringCanonicalUrl,
	canonicalUrl,
	slug,
	leftContent, // used for Structured Data - FAQ
}) => {
	useGlobalMessageHandler({
		googleAdsConversionId: configuration.marketing?.googleAdsConversionId,
	});
	useUtmSessionStorage();
	const imageURL = appConfig.baseURL + seo.image.formats.medium?.url;
	const structuredData = leftContent && createStructuredData(leftContent);
	return (
		<>
			<Head>
				{/* Primary Meta Tags */}
				<title>{seo.title}</title>
				<meta name="title" content={seo.title} />
				<meta name="description" content={seo.description} />
				<meta name="format-detection" content="telephone=no" />

				{/* Open Graph / Facebook */}
				<meta property="og:type" content="website" />
				<meta property="og:url" content={appConfig.baseURL + '/'} />
				<meta property="og:title" content={seo.title} />
				<meta property="og:description" content={seo.description} />
				<meta property="og:image" content={imageURL} />

				{/* Twitter */}
				<meta property="twitter:card" content="summary_large_image" />
				<meta property="twitter:url" content={appConfig.baseURL + '/'} />
				<meta property="twitter:title" content={seo.title} />
				<meta property="twitter:description" content={seo.description} />
				<meta property="twitter:image" content={imageURL} />

				{/* Structured data FAQ */}
				{structuredData && (
					<script
						type="application/ld+json"
						dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
					/>
				)}

				{/* Trygg E-Handel */}
				{configuration.tryggEHandelId && (
					<script
						src={`https://cert.tryggehandel.net/js/script.js?id=${configuration.tryggEHandelId}`}
						defer
						async
					></script>
				)}

				{/* Canonical tag */}
				{useSelfReferringCanonicalUrl && (
					<link rel="canonical" href={getCanonicalUrl(slug)} />
				)}
				{!useSelfReferringCanonicalUrl && canonicalUrl && (
					<link rel="canonical" href={canonicalUrl} />
				)}

				{preload.map((url, ix) => (
					<link rel="preload" as="image" href={url} key={'preload-' + ix} />
				))}
			</Head>
			<Navbar
				configuration={configuration}
				logo={configuration.logo.url}
				symbol={configuration.symbol.url}
				symbolSiteLinks={configuration.globalKollarnaLinks}
				showSymbolSiteLinksInNavbar={configuration.showSymbolSiteLinksInNavbar}
				showSymbolSiteLinksWithNavbarMode={configuration.showSymbolSiteLinksWithNavbarMode}
				announcementMessage={configuration.announcementMessage}
				links={configuration.menuLinks}
				siteLinks={configuration.menuSiteLinks}
				defaultVisitType={configuration.wizardConfiguration.defaultVisitType}
			/>
			<PreviewBanner preview={preview} />
			<CookieConsent
				cookiebotDomainGroupId={configuration.cookiebotDomainGroupId}
				gtmId={configuration.marketing?.gtmId}
			/>
			<div id={'content'} className={styles.page}>
				{children}
			</div>
			<Footer
				companyInfo={configuration.companyInfo}
				logo={configuration.logo.url}
				links={configuration.footerLinks}
				tryggEHandelId={configuration.tryggEHandelId}
				legitScriptId={configuration.legitScriptId}
			/>
		</>
	);
};

Layout.defaultProps = {
	preload: [],
};

export default Layout;
