import React from "react";
import PropTypes from "prop-types";
import styles from "./link.module.scss";
import NextLink from "next/link";
import Text from "../text";
import Image from "../image";

const Link = ({
  href,
  text,
  variant,
  icon,
  iconWidth,
  iconHeight,
  children,
  onClick,
}) => {
  if (variant === "icon") {
    if (!iconWidth && !iconHeight) {
      iconWidth = iconHeight = 24;
    }
    return (
      <NextLink href={href}>
        <a className={styles.iconLink}>
          <Image src={icon} width={iconWidth} height={iconHeight} alt={text} />
        </a>
      </NextLink>
    );
  } else if (variant === "image") {
    if (!iconWidth && !iconHeight) {
      iconWidth = iconHeight = 24;
    }
    return (
      <NextLink href={href}>
        <a className={styles.link}>
          <Image src={icon} width={iconWidth} height={iconHeight} alt={text} />
        </a>
      </NextLink>
    );
  } else if (variant === "children") {
    return (
      <NextLink href={href}>
        <a className={styles.link + " d-block"}>{children}</a>
      </NextLink>
    );
  } else {
    const textVariant = variant == "text" ? "link" : "linkBigOnDesktop";
    return (
      <NextLink href={href}>
        <a className={styles.link} onClick={onClick}>
          <Text tag={textVariant}>{text}</Text>
        </a>
      </NextLink>
    );
  }
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string,
  variant: PropTypes.oneOf([
    "text",
    "bigOnDesktop",
    "icon",
    "image",
    "children",
  ]),
  icon: PropTypes.string,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  children: PropTypes.node,
};

Link.defaultProps = {
  variant: "text",
};

export default Link;
