import React from "react";
import Link from "next/link";
import styles from "./preview-banner.module.scss";

const PreviewBanner = ({ preview }) => {
  if (!preview) {
    return null;
  }

  return (
    <div className={styles.previewBanner}>
      Förhandsgranskning
      <Link href="/api/exit-preview">Stäng</Link>
    </div>
  );
};

export default PreviewBanner;
