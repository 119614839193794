import styles from "./sites-section.module.scss";
import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import Link from "@/components/link";
import Image from "@/components/image";
import { useDeviceDetect } from "hooks";

const SitesSection = ({ symbolSiteLinks, navbarMode }) => {
	const { isSmallScreen } = useDeviceDetect();

	const desktopSiteLink = (siteLink) => {
		return (
			<div className={styles.desktopSiteLink}>
				<Link href="/vara-kollar" text={siteLink.title} variant="children">
					<Image src={siteLink.symbol.url} alt={siteLink.title} height={48} />
					<br />
					{siteLink.title}
				</Link>
			</div>
		);
	};

	const mobileSiteLink = (siteLink) => {
		return (
			<div className={styles.mobileSiteLink}>
				<Link href={"/vara-kollar"} text={siteLink.title} variant="children">
					<Image src={siteLink.symbolMobileLogo?.url} alt={siteLink.title} height={48} />
				</Link>
			</div>
		);
	};

	return (
		<section className={`${styles.sitesSection} ${navbarMode ? styles.navbarMode : ""}`}>
			<div className={styles.sitesList}>
				<CarouselProvider
					naturalSlideWidth={130}
					naturalSlideHeight={48}
					totalSlides={symbolSiteLinks?.length}
					visibleSlides={isSmallScreen ? 1 : 7}
					orientation={"horizontal"}
					infinite={false}
					isIntrinsicHeight={true}
					playDirection={"forward"}
					currentSlide={isSmallScreen ? 1 : 0}
					interval={2000}
					isPlaying={isSmallScreen && true}
					dragEnabled={isSmallScreen && true}
				>
					<Slider className={isSmallScreen ? styles.slider : ""}>
						<div
							style={{
								display: "flex",
								maxWidth: "100%",
								flex: 1,
								overflow: "hidden",
							}}
						>
							{symbolSiteLinks.map((site, index) => (
								<Slide className={styles.siteBlock} index={index} key={site.title}>
									{!isSmallScreen && !navbarMode && desktopSiteLink(site)}
									{(isSmallScreen || navbarMode) && mobileSiteLink(site)}
								</Slide>
							))}
						</div>
					</Slider>
				</CarouselProvider>
			</div>
		</section>
	);
};

export default SitesSection;
