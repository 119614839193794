import Head from "next/head";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";

const CookieConsent = ({ cookiebotDomainGroupId, gtmId }) => {
	const [allowStatistics, setAllowStatistics] = useState(false);

	const router = useRouter();

	useEffect(() => {
		if (typeof Cookiebot !== "undefined" && typeof Cookiebot.consent !== "undefined") {
			if (Cookiebot.consent.statistics) setAllowStatistics(true);
		}

		window.addEventListener(
			"CookiebotOnAccept",
			() => {
				setAllowStatistics(Cookiebot.consent.statistics);
			},
			false
		);
	}, [router]);

	if (!cookiebotDomainGroupId || !gtmId) {
		return null;
	}

	return (
		<>
			<Head>
				{allowStatistics && (
					<>
						<link rel="preconnect" href="https://googleads.g.doubleclick.net" />
						<link rel="preconnect" href="https://www.googleadservices.com" />
					</>
				)}
				<>
					<script
						dangerouslySetInnerHTML={{
							__html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${gtmId}');
                `,
						}}
					/>
					<noscript>
						<iframe
							src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
							height="0"
							width="0"
							style={{ display: "none", visibility: "hidden" }}
						></iframe>
					</noscript>
				</>
			</Head>
		</>
	);
};

CookieConsent.propTypes = {
	cookiebotDomainGroupId: PropTypes.string,
};

export default CookieConsent;
